import { BaseObject } from '@/types'
import { cloneDeep } from 'lodash-es'
import { TickTypes, TickNum } from '@/types'

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path: string) {
  const isExternal = /^(https?:|http?:|mailto:|tel:)/.test(path)
  return isExternal
}

// 深度优先遍历，遍历数组、遍历树结构对象
export function deepTraversal(node: BaseObject | BaseObject[]) {
  let stack = []
  if (Array.isArray(node)) {
    stack = cloneDeep(node)
  } else {
    stack.push(node)
  }
  const nodeList = []
  while (stack.length) {
    const item: any = stack.pop() // push + pop 后进先出
    nodeList.push(item)
    const children = item.children || []
    for (let i = children.length - 1; i >= 0; i--) {
      //使用逆序push()，以便栈底pop()时子元素是正序
      stack.push(children[i])
    }
  }
  return nodeList
}

export function getRandom(min: number, max: number, precision?: number) {
  const num = Math.random() * (max - min) + min
  const temp = Number(num.toFixed(precision ?? 0))
  return temp
}

// 生成指定数值区间、指定个数的数组
export function getRandomArray(min: number, max: number, length: number, precision?: number) {
  let arr: number[] = []
  for (let i = 0; i < length; i++) {
    // const num = Math.floor(Math.random() * (max - min + 1)) + min //含最大值，含最小值
    const num = Math.random() * (max - min) + min
    const temp = Number(num.toFixed(precision ?? 0))
    arr.push(temp)
  }
  return arr
}

// 取一维、二维数组最小值
export function getMin(list: any[]) {
  let min = 0
  if (list.length) {
    if (Array.isArray(list[0])) {
      let minList = []
      for (let listItem of list) {
        let currentMin = Math.min(...listItem)
        minList.push(currentMin)
      }
      min = Math.min(...minList)
    } else {
      min = Math.min(...list)
    }
  }
  return min
}

// 取一维、二维数组最大值
export function getMax(list: any[]) {
  let max = 0
  if (list.length) {
    if (Array.isArray(list[0])) {
      let maxList = []
      for (let listItem of list) {
        let currentMax = Math.max(...listItem)
        maxList.push(currentMax)
      }
      max = Math.max(...maxList)
    } else {
      max = Math.max(...list)
    }
  }
  return max
}

// Y轴最大刻度、最小刻度处理
export function getTickPricision(min: number, max: number, type: TickTypes, tickNum: TickNum) {
  let minWithPri: number
  let maxWithPri: number
  // 数组数据中最大值、最小值差值5段，最大值、最小值各加减一份，放大最大值、最小值之间折线的波形
  if (type === 'pieces') {
    const tick = (max - min) / 5
    minWithPri = min - tick
    maxWithPri = max + tick
  } else {
    // 以数组数据中最大值、最小值作为最大、最小刻度
    minWithPri = min
    maxWithPri = max
  }
  if (tickNum === 0) {
    // 取整
    minWithPri = Math.floor(min)
    maxWithPri = Math.ceil(max)
  } else if (tickNum === 10) {
    // 以10取整
    minWithPri = Math.floor(min / 10) * 10
    maxWithPri = Math.ceil(max / 10) * 10
  } else {
    // 保留1-5位小数位
    minWithPri = Number(min.toFixed(tickNum))
    maxWithPri = Number(max.toFixed(tickNum))
  }
  return [minWithPri, maxWithPri]
}
