import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import WebLayout from '../views/layout/webLayout.vue' //左导航
import WebLayout from '../views/layout/webLayout2.vue' //上导航

/* 
name: string    cacheView使用此name标识需缓存的组件名，vue-devtool 默认使用文件名生成组件名，文件名需与此name保持一致。或者组件文件内定义了defineOptions({name})，定义的name与此name保持一致
meta:{
  title:string        必填，页面名称，显示在标题栏
  roles:string[]      必填，可访问页面的权限列表。暂时设为['admin']，登录页面给所有用户设置了'admin'权限 TODO
  hidden?:boolean     可选，是否隐藏导航，通常：详情页面传参进入不显示在导航列表
  icon?:string        可选，使用左侧导航时，显示的导航图标。头部导航默认隐藏图标。使用 components/SvgIcon组件 + iconfont图标库项目
  alwaysShow?:boolean 可选，是否显示子导航，当只有一个 hidden不为true 的子元素时，设置是否渲染子导航。alwaysShow:true 渲染 el-sub-menu，否则渲染 el-menu
  keepAlive?:boolean  可选，是否缓存页面
}
children 下的子路由渲染在父组件下，父组件如果是WebLayout，webLayout.vue页面设置了<router-view>路由出口，自动渲染展示子路由。父组件如果不是WebLayout，应在组件内设置对应的<router-view>路由出口，否则不要配置成children嵌套关系

*/

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'WebLogin',
    component: () => import('../views/webLogin.vue'),
    meta: { title: '登录', hidden: true },
  },

  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: { title: '404', hidden: true },
  },
  {
    path: '/',
    redirect: '/home/index',
    name: 'Root',
    meta: { hidden: true },
  },
]

export const asyncRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    component: WebLayout,
    redirect: '/home/index',
    name: 'Home',
    meta: { title: '首页', icon: 'icon-tubiao-bingtu', roles: ['admin'], keepAlive: true },
    children: [
      {
        path: 'index',
        name: 'DeviceMonitor',
        component: () => import('../views/Home/DeviceMonitor.vue'),
        meta: { title: '首页', icon: 'icon-tubiao-bingtu', roles: ['admin'], keepAlive: true },
      },
    ],
  },
  {
    path: '/alarmList',
    component: WebLayout,
    redirect: '/alarmList/index',
    name: 'AlarmList',
    meta: { title: '报警界面', icon: 'icon-gaojing', roles: ['admin'] },
    children: [
      {
        path: 'index',
        name: 'AlarmListIndex',
        component: () => import('../views/Alarm/AlarmList.vue'),
        meta: { title: '报警界面', icon: 'icon-gaojing', roles: ['admin'] },
      },
      {
        path: 'detail',
        name: 'AlarmDetail',
        component: () => import('../views/Alarm/AlarmDetail.vue'),
        meta: { title: '报警详情', hidden: true },
      },
    ],
  },
  {
    path: '/dataAnalysis',
    component: WebLayout,
    redirect: '/dataAnalysis/index',
    name: 'DataAnalysis',
    meta: { title: '安全指标分析', icon: 'icon-tubiao-bingtu', roles: ['admin'], keepAlive: true },
    children: [
      {
        path: 'index',
        name: 'DataAnalysisIndex',
        component: () => import('../views/DataAnalysis/DataAnalysis.vue'),
        meta: { title: '安全指标分析', icon: 'icon-tubiao-bingtu', roles: ['admin'], keepAlive: true },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...constantRoutes],
})

export default router
