import { BaseObject, LineChartItem, AllData, LineChartItemChild, ObjectList } from '@/types'
import { getMin, getMax } from '@/utils/index'
import { getLineOptions } from '@/utils/chart'
import * as echarts from 'echarts/core'
import type { EChartsOption } from 'echarts'
import { cloneDeep } from 'lodash-es'

/* 
  根据 lineChartList 配置生成折线图 series，为相应 options 赋值
*/
export const useGetLineSeries = (configureItem: LineChartItem, allData: AllData, target?: any, key?: string) => {
  if (!allData?.timeData?.length) {
    return
  }
  let timeData: string[] = []
  timeData = allData?.timeData?.map((item: string) => {
    return (item + '').slice(5) || '--'
  })

  //n条折线 二维数组数据
  let yAxisListAll = []
  if (configureItem.lineNum === 1) {
    // 优先使用dataKey取值，其次prop
    if (configureItem.dataKey) {
      const obj = allData[configureItem.prop] as ObjectList
      yAxisListAll = [obj?.[configureItem.dataKey]]
    } else {
      yAxisListAll = [allData[configureItem.prop]]
    }
  } else {
    // 多个关联的折线绘制n条折线时，优先使用 dataKey+index 索引取值volts:{volt1:[], volt240:[]}，其次prop
    if (configureItem.dataKey) {
      for (let i = 1; i <= configureItem.lineNum; i++) {
        const obj = allData[configureItem.prop] as BaseObject
        yAxisListAll.push(obj[configureItem.dataKey + i] || [])
      }
    } else {
      // 多个无关联的折线绘制n条折线时
      if (configureItem?.children?.length) {
        for (let i = 0; i < configureItem?.children.length; i++) {
          yAxisListAll.push(allData[configureItem.children[i].prop] || [])
        }
      }
    }
  }

  const seriesItem: EChartsOption = {
    name: '',
    type: 'line',
    // symbol: 'none',
    // smooth: true,
    symbolSize: 8,
    showSymbol: false,
    label: {
      show: false, //折线图上的数值鼠标放上去时不显示
    },
    itemStyle: {},
    markPoint: undefined, //handlePaintWidthStorage设置了loading.value=true 触发重绘，echarts图表仍然保留了之前的markPoint，是否Echarts内部重用策略？TODO
    data: [],
  }
  // 折线颜色
  if (configureItem.lineNum === 1 && configureItem.color) {
    seriesItem.itemStyle = { color: configureItem.color }
  }
  const seriesList: EChartsOption[] = yAxisListAll.map((item, index) => {
    return {
      ...seriesItem,
      name:
        yAxisListAll.length === 1
          ? configureItem.label
          : configureItem.children?.[index]?.label || configureItem.label + (index + 1),
      data: item && item.length ? item : Array(timeData.length).fill(0), //空数据的补0
    }
  })
  // 区域填充渐变色
  if (configureItem.lineNum === 1 && configureItem.gradientColor) {
    seriesList[0].areaStyle = {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: configureItem.gradientColor[0], // 0% 处的颜色
        },
        {
          offset: 0.5,
          color: configureItem.gradientColor[1],
        },
        {
          offset: 1,
          color: configureItem.gradientColor[2],
        },
      ]),
    }
  }
  // 为 options 赋值
  const grid = {
    left: 30,
    right: 30,
    top: 60,
    bottom: 30,
    containLabel: true,
  }
  if (configureItem.option) {
    if (!configureItem.option.value || JSON.stringify(configureItem.option.value) === '{}') {
      // 多个图表垂直对齐左侧Y轴的 grid
      const verticalMulChartGrid = {
        left: 60,
        right: 10,
        top: 30,
        bottom: 25,
        show: true,
        borderColor: '#888', //坐标系网格边框，不包含坐标轴标签，对齐多个grid
      }
      // 自定义配置项
      let obj: any = { grid }
      if (configureItem?.optionConfig?.customOptions) {
        obj = Object.assign(obj, configureItem?.optionConfig?.customOptions)
      }
      if (configureItem?.optionConfig?.verticalMulChart) {
        obj.grid = verticalMulChartGrid
      }
      configureItem.option.value = getLineOptions(
        configureItem.label + (configureItem.unit ?? ''),
        obj,
        !!configureItem.optionConfig?.openCustomTooltip,
      )
      // 多个图表垂直对齐左侧Y轴的 axisLabel
      if (configureItem?.optionConfig?.verticalMulChart) {
        configureItem.option.value.xAxis.axisLabel = {
          width: 70,
        }
      }
    }

    configureItem.option.value.xAxis.data = timeData
    configureItem.option.value.series = seriesList
  } else {
    if (target !== undefined && key !== undefined) {
      if (!target[key]) {
        // 为图表options初始化配置项
        target[key] = getLineOptions(configureItem.label + (configureItem.unit ?? ''), { grid }, false)
      }
      target[key].xAxis.data = timeData
      target[key].series = seriesList
    }
  }

  // 增加图例
  if (configureItem.legend) {
    if (configureItem.option) {
      configureItem.option.value.legend = {
        show: true,
        data: configureItem.legend,
      }
    } else {
      if (target !== undefined && key !== undefined) {
        target[key].legend = {
          show: true,
          data: configureItem.legend,
        }
      }
    }
  }

  // 第一个Y轴最大、最小刻度值调整
  if (configureItem.tickPricision?.length) {
    const arr = configureItem.tickPricision
    let isValidNum = true
    if (typeof arr[1] !== 'number') {
      isValidNum = false
    } else if (arr[1] < 0 || arr[1] > 10) {
      isValidNum = false
    }
    if (!isValidNum) {
      return
    }
    const min = Number(getMin(yAxisListAll))
    const max = Number(getMax(yAxisListAll))
    let minWithPri: number
    let maxWithPri: number
    // 数组数据中最大值、最小值差值5段，最大值、最小值各加减一份，放大最大值、最小值之间折线的波形
    if (arr[0] === 'pieces') {
      const tick = (max - min) / 5
      minWithPri = min - tick
      maxWithPri = max + tick
    } else {
      // 以数组数据中最大值、最小值作为最大、最小刻度
      minWithPri = min
      maxWithPri = max
    }
    if (arr[1] === 0) {
      // 取整
      minWithPri = Math.floor(min)
      maxWithPri = Math.ceil(max)
    } else if (arr[1] === 10) {
      // 以10取整
      minWithPri = Math.floor(min / 10) * 10
      maxWithPri = Math.ceil(max / 10) * 10
    } else {
      // 保留1-5位小数位
      minWithPri = Number(min.toFixed(arr[1]))
      maxWithPri = Number(max.toFixed(arr[1]))
    }
    if (configureItem.option) {
      configureItem.option.value.yAxis[0].min = minWithPri
      configureItem.option.value.yAxis[0].max = maxWithPri
    } else {
      if (target !== undefined && key !== undefined) {
        target[key].yAxis[0].min = minWithPri
        target[key].yAxis[0].max = maxWithPri
      }
    }
  }

  // 多个Y轴，默认根据 configureItem?.children[index].yAxisIndex 及接口数据生成
  let isMultipleYAxis = false
  if (configureItem?.children?.length) {
    isMultipleYAxis = configureItem?.children?.filter(item => !!item.yAxisIndex).length > 0
  }
  if (isMultipleYAxis) {
    if (configureItem.option) {
      handleYAxisList(configureItem, configureItem.option, 'value', allData)
    } else {
      if (target !== undefined && key !== undefined) {
        handleYAxisList(configureItem, target, key, allData)
      }
    }
  }
}

// 右侧Y配置项
const getYAxisItem = (color?: string) => {
  return {
    type: 'value',
    position: 'right',
    min: 'dataMin',
    max: 'dataMax',
    nameTextStyle: {
      padding: [0, 16, -4, 20],
    },
    //Echarts-将图表网格线设置成虚线（图表横线网格）
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: color ?? '#efefef',
      },
      show: false,
    },
    axisLine: {
      lineStyle: {
        show: true,
        color: color ?? '#000',
        width: 1,
      },
      show: true,
    },
    axisLabel: {
      showMinLabel: true,
      showMaxLabel: true,
    },
  }
}

// Y轴刻度：>10以10向上取整，[1,10]取整，[0,1]两位小数，0，[-1，0]两位小数，[-10,-1]取整，<-10以10向下取整
const getTickNum = (num: number, type?: string) => {
  let value: string | number = 0
  if (num >= 10) {
    value = Math.ceil(num / 10) * 10
  } else if (num < 10 && num >= 1) {
    value = Math.ceil(num)
  } else if (num > 0 && num < 1) {
    const precision = (num + '').split('.')?.[1]?.length
    if (precision && precision > 2) {
      value = Number(num.toFixed(3))
    } else {
      value = Number(num.toFixed(2))
    }
  } else if (num === 0) {
    value = 0
  } else if (num < 0 && num > -1) {
    const precision = (num + '').split('.')?.[1]?.length
    if (precision && precision > 2) {
      value = Number(num.toFixed(3))
    } else {
      value = Number(num.toFixed(2))
    }
  } else if (num <= -1 && num > -10) {
    value = Math.floor(num)
  } else {
    value = Math.floor(num / 10) * 10
  }
  return value
}

// 根据 configureItem?.children[index].yAxisIndex 及接口数据生成多个Y轴
const colorList = ['#536fc6', '#856f8f', '#fac857', '#6fcfde', '#90cc74']
const handleYAxisList = (configureItem: LineChartItem, target: any, key: string, allData: BaseObject) => {
  // const absMinList: number[] = []
  const absMaxList: number[] = [] //configureItem?.children 中每一项的最大值组中的数组
  configureItem?.children?.map((childItem: LineChartItemChild) => {
    const absList = allData[childItem.prop].map((item: number) => Math.abs(item))
    // absMinList.push(getMin(absList))
    absMaxList.push(getMax(absList))
  })
  const absMaxInChildren = getMax(absMaxList)
  const maxCount = absMaxList.filter(item => item > 0)

  let count = 0 //Y轴个数
  // 所有序列有多个值>0时，绘制多个Y轴。
  if (absMaxInChildren > 0 && maxCount.length > 1) {
    absMaxList.map((maxItem, maxIndex) => {
      // 限制有值>0再绘制对应系列的Y轴。考虑压差的值存在0.00x的情况
      if (maxItem > 0) {
        const childItem = configureItem?.children?.[maxIndex]
        const arr = childItem?.yAxisIndex //[yAxisIndex:number, {position:'left' | 'right', offset:number}]
        if (childItem && arr && arr.length) {
          if (arr[0] !== 0) {
            count++
            target[key].yAxis[count] = cloneDeep(getYAxisItem(colorList[arr[0]]))
            // 默认第二个Y轴起全放在右侧，以此计算offset
            target[key].yAxis[count].position = 'right'
            target[key].yAxis[count].name = childItem.label + childItem.unit
            target[key].yAxis[count].offset = (count - 1) * 60
            target[key].series[arr[0]].yAxisIndex = count ?? 0
            // target[key].yAxis[0].alignTicks = true // 可能造成刻度自动保留过长的小数位的问题
            // target[key].yAxis[count].minInterval = 0.001 // 当最大值、最小值是距离较近的小数时，可能存在只有最大值、最小值两个刻度，丢失其他刻度
            // target[key].grid = {
            //   top: '12%',
            //   right: '14%',
            //   bottom: '5%',
            //   left: '5%',
            //   containLabel: true,
            // }
          } else {
            //多个Y轴时左侧Y轴处理（默认左侧Y轴的 yAxisIndex:0）
            target[key].yAxis[0].axisLine.lineStyle.color = colorList[0]
            target[key].yAxis[0].name = childItem.label + childItem.unit
          }
          target[key].color = colorList //多个Y轴时，图例颜色与Y轴颜色统一
        }
      }
    })
  }
  // console.log('yAxis', target[key].yAxis)
}
