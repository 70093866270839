import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css'
import './assets/css/index.less'

// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { globalRegister } from './global' //ElementPlus按需加载、全局组件、全局属性

import '@/assets/iconfont/iconfont.js'

import './permission'
import store from '@/store'

const app = createApp(App)
// app.use(ElementPlus)
app.use(globalRegister)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store)
app.use(router)

router.isReady().then(() => {
  app.mount('#app')
})
