import { App } from 'vue'
// import "element-plus/lib/theme-chalk/base.css"; //codeywhy代码
// import "element-plus/theme-chalk/base.css"; //实际node_modules下文件路径
// import 'element-plus/es/components/message/style/css' //官网代码
import {
  ElAside,
  ElAvatar,
  ElButton,
  ElCheckbox,
  ElContainer,
  ElDatePicker,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElForm,
  ElFormItem,
  ElHeader,
  ElInput,
  ElLink,
  ElMain,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElOption,
  ElRadio,
  ElRow,
  ElCol,
  ElSelect,
  ElSubMenu,
  ElTabPane,
  ElTabs,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElImage,
  ElDialog,
  ElTree,
  ElCard,
  ElProgress,
  ElIcon,
  ElAutocomplete,
  ElCascader,
  ElTag,
  ElTooltip,
  ElBacktop,
  ElSteps,
  ElStep,
  // ElLoading
} from 'element-plus'

const components = [
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
  ElRadio,
  ElTabs,
  ElTabPane,
  ElCheckbox,
  ElLink,
  ElContainer,
  ElHeader,
  ElMain,
  ElAside,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElSubMenu,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElAvatar,
  ElCol,
  ElRow,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElImage,
  ElDialog,
  ElTree,
  ElCard,
  ElProgress,
  ElIcon,
  ElAutocomplete,
  ElCascader,
  ElTag,
  ElTooltip,
  ElBacktop,
  ElSteps,
  ElStep,
  // ElLoading
]

export default function (app: App): void {
  for (const component of components) {
    app.component(component.name, component)
  }
}
