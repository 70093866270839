import { defineStore } from 'pinia'

export interface PageState {
  url: string
  expiredUrl: string
}
const updateStorage = (type: 'url' | 'expiredUrl', value: string) => {
  const pageUrl = sessionStorage.getItem('pageUrl')
  let obj: PageState = { url: '', expiredUrl: '' }
  if (pageUrl) {
    obj = JSON.parse(pageUrl)
  }
  obj[type] = value
  sessionStorage.setItem('pageUrl', JSON.stringify(obj)) // 存储pageUrl
}

export const usePageStore = defineStore('page', {
  state: (): PageState => {
    return {
      url: '', //登录前要去向的页面，登录后跳转进入
      expiredUrl: '', //登录失效所在的页面，登录后跳转进入
    }
  },
  actions: {
    setUrl(state: string) {
      this.url = state
      updateStorage('url', state)
    },
    setExpiredUrl(state: string) {
      this.expiredUrl = state
      updateStorage('expiredUrl', state)
    },
    refreshStore() {
      const pageUrl = sessionStorage.getItem('pageUrl')
      if (pageUrl) {
        const obj = JSON.parse(pageUrl)
        if (!this.url && !this.expiredUrl) {
          this.url = obj.url
          this.expiredUrl = obj.expiredUrl
        }
      }
    },
  },
})
