import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash-es'

export interface TokenInfo {
  access_token: string
  enhance?: string
  expires_in?: number
  refresh_token?: string
  scope?: string
  token_type: string
}

export interface UserInfo {
  username: string
  password: string
  tokenInfo: TokenInfo
  rolesList?: string[]
}
export interface UserState {
  counter: number
  userInfo: UserInfo
  hideTokenExpiredPop: boolean
  macFromOuter?: string
}

export const useUserStore = defineStore('main', {
  // vuex4.0、pinia 的 state 使用函数
  state: (): UserState => {
    return {
      counter: 0,
      userInfo: {
        username: '',
        password: '',
        tokenInfo: {
          token_type: '',
          access_token: '',
        },
        rolesList: [],
      },
      hideTokenExpiredPop: false, //token失效时，当前页面如果连续发出了多个请求，避免弹出多个登录提示弹框。初始化false，允许弹出。
      macFromOuter: '', //第三方传入的vin号，首页默认选中
    }
  },
  actions: {
    increment() {
      this.counter++
    },
    setHideTokenExpiredPop(state: boolean) {
      this.hideTokenExpiredPop = state
    },
    setUserInfo(userInfo: UserInfo) {
      this.userInfo = cloneDeep(userInfo)
      localStorage.setItem('userInfo', JSON.stringify(userInfo)) // 存储userInfo
    },
    refreshStore() {
      const userInfo = localStorage.getItem('userInfo')
      if (userInfo) {
        const obj = JSON.parse(userInfo)
        this.userInfo = obj
      }
      // 使用sessionStorage 不影响其他非被载入的网页
      const macFromOuter = sessionStorage.getItem('macFromOuter')
      if (macFromOuter) {
        this.macFromOuter = macFromOuter
      }
    },
    clearUserInfo() {
      this.userInfo = {
        username: '',
        password: '',
        tokenInfo: {
          token_type: '',
          access_token: '',
        },
        rolesList: [],
      }
      localStorage.removeItem('userInfo')
      // sessionStorage.removeItem('macFromOuter') // 登出不清空macFromOuter
    },
  },
})
