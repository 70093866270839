import { App } from 'vue'

// import { formatUtcString } from '@/utils/date-format'

export default function registerProperties(app: App) {
  app.config.globalProperties.$filters = {
    // formatTime(value: string) {
    //   return formatUtcString(value)
    // }
  }
  app.config.globalProperties.ConstantData = {
    requestErrorMsg: '访问网络错误',
  }
  app.config.globalProperties.$utils = {}
}
