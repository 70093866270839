import { oauthToken } from '@/api/common'
import { useUserStore } from '@/store/base'
import { usePageStore } from '@/store/page'
import pinia from '@/store'
import router from '../router' //在自定义中js中不可使用useRouter()

const userStore = useUserStore(pinia)
const pageStore = usePageStore(pinia)

// 登录失效，刷新token，（router.go 页面闪烁，使用request.ts无感知刷新）
export async function useRefreshToken() {
  const params = new URLSearchParams()
  params.append('grant_type', 'refresh_token')
  params.append('client_id', 'pps')
  params.append('client_secret', '123456')
  params.append('scope', 'all')
  params.append('refresh_token', userStore?.userInfo?.tokenInfo.refresh_token || '')

  // 此处不捕获错误，继续把错误抛出给request.ts，以处理后续逻辑
  return await oauthToken(params).then(res => {
    const rolesList = ['admin'] //暂时给所有用户开放admin权限  TODO
    userStore.setUserInfo({
      username: userStore?.userInfo?.username,
      password: userStore?.userInfo?.password,
      tokenInfo: res.data,
      rolesList,
    })
    // userStore.setHideTokenExpiredPop(false)
    // console.log('refresh_token_then ---', pageStore.url, pageStore.expiredUrl)
    // if (pageStore.url) {
    //   router.replace(pageStore.url)
    //   pageStore.setUrl('')

    //   // router.go(0)
    // } else if (pageStore.expiredUrl) {
    //   router.replace(pageStore.expiredUrl)
    //   pageStore.setExpiredUrl('')

    //   // router.go(0)
    // } else {
    //   router.replace('/')

    //   // router.go(0)
    // }
  })
  // .catch(error => {
  //   console.log('refresh_token_catch ---', error)
  // })
}
