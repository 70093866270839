import { BaseObject } from '@/types'

// 折线面积图渐变色，折线颜色border，面积gradient
export const colorMap = {
  blueGradient: {
    border: '#0180ff',
    gradient: ['#81befd', '#e4f2ff', '#fff'],
  },
  greenGradient: {
    border: '#4fd6d2',
    gradient: ['#d7f4f8', '#eefcfd', '#fff'],
  },
  purpleGradient: {
    border: '#706be7',
    // gradient: ['#e7effe', '#f2f6ff', '#fff'],
    gradient: ['#a6a2f4', '#e7effe', '#fff'],
  },
  yellowGradient: {
    border: '#e2af23',
    gradient: ['#fdd055', '#fff8eb', '#fff'],
  },
}

// name Y轴名称，customOptions 自定义配置项，openCustomTooltip 是否使用默认的tooltip配置
export const tooltipOptions = {
  formatter: function (params: any) {
    // console.log(params)
    let dom = '<div style="width:460px; height:150px;  font-size: 14px; color:#333; overflow-y:auto;">'
    dom += '<div>' + params[0].axisValue + '</div>'
    dom += '<div style="display:flex; flex-flow:row wrap;">'
    if (Array.isArray(params)) {
      const arr = params.map(item => Number(item.value))
      const max = Math.max(...arr)
      const min = Math.min(...arr)
      dom += '<div style="width:100%; font-weight:700;">'
      dom += '<span style="display:inline-block; padding:5px 10px; color:#fac858;">最大值： ' + max + '</span>'
      dom += '<span style="display:inline-block; padding:5px 10px; color:#3ba272;">最小值： ' + min + '</span>'
      dom += '</div>'

      params.map(item => {
        dom += '<span style="display:inline-block; padding:5px 10px 0 10px; ">'
        // dom += item.marker
        dom +=
          '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color: #abbbc6;"></span>'
        dom += '<span style="display:inline-block; min-width:55px; margin-left: 4px;">' + item.seriesName + '</span>'

        let styleString = 'display:inline-block; width:35px; margin-left: 5px;'
        if (item.value === min) {
          styleString += 'font-weight:700; color:#3ba272'
        } else if (item.value === max) {
          styleString += 'font-weight:700; color:#fac858'
        }
        dom += '<span style="' + styleString + '">' + item.value + '</span>'
        dom += '</span>'
      })
    }
    dom += '</div></div>'
    return dom
  },
}
export const getLineOptions = (name: string, customOptions: BaseObject, openCustomTooltip = false) => {
  // tooltip默认样式
  let obj = {}
  if (openCustomTooltip) {
    obj = tooltipOptions
  }
  return {
    tooltip: {
      trigger: 'axis',
      enterable: !!openCustomTooltip, //鼠标是否可进入提示框浮层中
      confine: true, //是否将 tooltip 框限制在图表的区域内
      // axisPointer: {
      //   label: {
      //     precision: 0,
      //   },
      // },
      ...obj,
    },
    legend: {
      show: false,
    },
    calculable: true,
    grid: {
      top: '12%',
      right: '5%',
      bottom: '5%',
      left: '5%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      // name: '时间',
      name: '',
      nameTextStyle: {
        padding: [0, 0, 0, -5],
      },
      axisTick: {
        alignWithLabel: true,
      },
      //设置 X 轴的样式
      axisLine: {
        lineStyle: {
          show: true,
          color: '#000',
          width: 1,
        },
      },
      //设置 X 轴的样式，浅色样式
      // axisLine: {
      //   lineStyle: {
      //     show: true,
      //     type: 'dashed',
      //     color: '#efefef',
      //     width: 1,
      //   },
      // },
      // axisLabel: {
      //   color: '#b7b8bf',
      // },
      data: [] as any[],
    },
    yAxis: [
      {
        name,
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        nameTextStyle: {
          padding: [0, 16, -4, 20],
        },
        //Echarts-将图表网格线设置成虚线（图表横线网格）
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: '#efefef',
          },
          show: true,
        },
        axisLine: {
          lineStyle: {
            show: true,
            color: '#000',
            width: 1,
          },
          show: true,
        },
        // 浅色样式
        // axisLine: {
        //   lineStyle: {
        //     show: true,
        //     type: 'dashed',
        //     color: '#efefef',
        //     width: 1,
        //   },
        //   show: true,
        // },
        // axisLabel: {
        //   show: true,
        //   color: '#b7b8bf',
        // },
        axisLabel: {
          showMinLabel: true,
          showMaxLabel: true,
        },
      },
    ],
    series: [] as any[],
    ...customOptions,
  }
}
// 盒须图
export const getBoxplotChartOptions = (yAxisName: string, title?: string, xAxisData?: any[]) => {
  let test: any[] = []
  if (xAxisData) {
    test = [
      {
        transform: {
          type: 'boxplot',
          config: {
            //添加左三盒须图表X轴的时间坐标
            itemNameFormatter: function (params: any) {
              return xAxisData[params.value]
            },
          },
        },
      },
    ]
  }
  let titleOption: any[] = []
  if (title) {
    titleOption = [
      {
        text: title ?? '',
        left: 'center',
        padding: [5, 15, 15, 25],
        textStyle: {
          color: '#000',
          fontStyle: 'normal',
          fontWeight: '400',
          fontFamily: 'sans-serif',
          fontSize: 14,
        },
      },
    ]
  }
  return {
    grid: {
      bottom: '9%',
    },
    // grid: {
    //   left: '5%',
    //   top: '12%',
    //   right: '5%',
    //   bottom: '5%',
    //   containLabel: true,
    // },
    title: titleOption,
    dataset: [
      {
        source: [] as any[],
      },
      ...test,
    ],
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      // name: '时间',
      nameTextStyle: {
        padding: [0, 0, 0, -10],
      },
      type: 'category',
      boundaryGap: true,
      nameGap: 20,
      splitArea: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          show: true,
          color: '#000',
          width: 1,
        },
      },
      data: [] as any[],
    },
    yAxis: {
      name: yAxisName,
      type: 'value',
      nameTextStyle: {
        padding: [0, 16, -4, 20],
      },
      min: 'dataMin', //取最小值为最小刻度
      max: 'dataMax', //取最大值为最大刻度
      //Echarts-将图表网格线设置成虚线（图表横线网格）
      // splitLine: {
      //   lineStyle: {
      //     type: 'dashed',
      //     color: '#464A4E',
      //   },
      //   show: true,
      // },
      // splitArea: {
      //   show: true,
      // },
      //设置 Y 轴的样式
      axisLine: {
        lineStyle: {
          show: true,
          color: '#000',
          width: 1,
        },
      },
    },
    series: [
      {
        name: title,
        type: 'boxplot',
        datasetIndex: 1,
        boxWidth: [3, 30],
        data: [] as any[],
      },
    ],
  }
}

// 仪表盘，百分比环形样式
export const getGaugeOptions = (color: string, data: any[]) => {
  return {
    color: [color || '#005AD4'],
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        //当前进度
        progress: {
          show: true,
          roundCap: true,
          width: 8,
          overlap: false, //多组数据时进度条是否重叠
          clip: false, //是否裁掉超出部分
        },
        pointer: {
          show: false,
        },
        //仪表盘轴线相关配置
        axisLine: {
          lineStyle: {
            width: 8,
            // color: [[1, '#07265c']],
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        title: {
          fontSize: 20,
          fontWeight: 'bold',
          color: '#409DEA',
        },
        data,
      },
    ],
  }
}
